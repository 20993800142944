import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { BigHeadline } from "../components/styles/headlines"

import PriceList from "../images/angebot-brautstyling-2024.pdf"

const PricingPage = () => (
  <Layout>
    <Seo title="Preise und Leistungen" />
    <section className="container px-6  mx-auto flex justify-center mt-60 mb-20">
      <div className="w-full lg:w-3/4">
        <BigHeadline>Preise & Leistungen</BigHeadline>
        <p>Solltet Ihr euren vereinbarten Termin nicht wahrnehmen können, bitten wir diesen spätestens 24 Stunden vorher abzusagen. Anderenfalls sehen wir uns gezwungen, euch 50 % des Preises der vereinbarten Dienstleistung in Rechnung zu stellen, sofern wir den Termin nicht anderweitig vergeben können. Wir bitten um euer Verständnis</p><br />
        <p>Achtung Neukunden Balayage: Wir weisen daraufhin, dass wir bei Neukunden dieser Dienstleistung eine Anzahlung verlangen. Bei nicht rechtzeitiger Terminabsage wird die Anzahlung als Entschädigungsaufwand nicht zurückgezahlt.</p>
        {/************************************************************************************************** Herren*/}
        <details class="mt-8">
          <summary class="border-b-2 border-main pb-3 ">Herren</summary>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Haarschnitt trocken Herren</p>
            <p class="ml-2">24,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Haarschnitt Waschen Herren</p>
            <p class="ml-2">26,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Herrenpaket Deluxe <br />(Waschen, Schneiden, Waschen, Kopfmassage 10 min, Styling) </p>
            <p class="ml-2">36,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Herren Color Paket <br />(Waschen, Schneiden, Föhnen, Färben, Styling) </p>
            <p class="ml-2">70,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Bart und Haarkonturen/Bartkonturen</p>
            <p class="ml-2">nach Aufwand</p>
          </div>
        </details>
        {/************************************************************************************************** Damen*/}
        <details>
          <summary class="border-b-2 border-main pb-3 mt-16">Damen</summary>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Haarschnitt trocken Damen kurz</p>
            <p class="ml-2">30,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Haarschnitt trocken Damen mittel</p>
            <p class="ml-2">35,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Haarschnitt trocken Damen lang</p>
            <p class="ml-2">39,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Waschen Föhnen Damen kurz</p>
            <p class="ml-2">25,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Waschen Föhnen Damen mittel</p>
            <p class="ml-2">35,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Waschen Föhnen Damen lang</p>
            <p class="ml-2">38,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Waschen Schneiden Föhnen Damen kurz</p>
            <p class="ml-2">38,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Waschen Schneiden Föhnen Damen mittel</p>
            <p class="ml-2">48,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Waschen Schneiden Föhnen Damen lang</p>
            <p class="ml-2">57,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Neuhaarschnitt Damen</p>
            <p class="ml-2">67,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung Color Damen kurz <br />(Waschen, Schneiden, Pflegen, Längenausgleich, Ansatzfarbe, Styling) </p>
            <p class="ml-2">102,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung Color Damen mittel <br />(Waschen, Schneiden, Pflegen, Längenausgleich, Ansatzfarbe, Styling) </p>
            <p class="ml-2">112,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung Color Damen lang <br />(Waschen, Schneiden, Pflegen, Längenausgleich, Ansatzfarbe, Styling) </p>
            <p class="ml-2">130,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung New Color Damen kurz <br />(Waschen, Schneiden, Pflegen, Ansatzfarbe Länge und Spitze, Styling) </p>
            <p class="ml-2">113,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung New Color Damen mittel <br />(Waschen, Schneiden, Pflegen, Ansatzfarbe Länge und Spitze, Styling) </p>
            <p class="ml-2">128,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Grundpaket New Color Damen lang <br />(Waschen, Schneiden, Pflegen, Ansatzfarbe Länge und Spitze, Styling) </p>
            <p class="ml-2">145,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Balayage All inklusiv kurz <br /> (WSF, Pflege, Plex, glossing/Pastel, ggf. Ansatzfarbe Styling) </p>
            <p class="ml-2">175,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Balayage All inklusive mittel (WSF, Pflege, Plex, glossing/Pastel, ggf. Ansatzfarbe Styling, Heimpflegeberatung)</p>
            <p class="ml-2">237,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Balayage All inklusive lang <br /> (WSF, Pflege, Plex, glossing/Pastel, ggf. Ansatzfarbe Styling, Heimpflegeberatung) </p>
            <p class="ml-2">279,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Paket gesundes Haar Plex (Haaraufbau + Föhnen) kurz</p>
            <p class="ml-2">40,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Paket gesundes Haar Plex (Haaraufbau + Föhnen) mittel</p>
            <p class="ml-2">44,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Paket gesundes Haar Plex (Haaraufbau + Föhnen) lang</p>
            <p class="ml-2">58,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Hochsteckfrisure Deluxe</p>
            <p class="ml-2">275,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Sonstige Steckfrisuren</p>
            <p>je Min. 1€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Dauerwellenpaket kurz/mittelall inkl. Paket (WSF, Pflege, DW, Styling)</p>
            <p class="ml-2">102,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Dauerwellenpaket lang all inkl. Paket (WSF, Pfleg, DW, Styling)</p>
            <p class="ml-2">131,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Dauerwellen Premiumpaket mit Vorbehandlung und Plex kurz/mittel</p>
            <p class="ml-2">118,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Dauerwellen Premiumpaket mit Vorbehandlung und Plex lang</p>
            <p class="ml-2">158,00€</p>
          </div>
        </details>
        {/************************************************************************************************** Kinder*/}
        <details>
          <summary class="border-b-2 border-main pb-3 mt-16">Kinder</summary>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Kinderhaarschnitte mit Waschen bis 12 Jahre</p>
            <p class="ml-2">19,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Kinderhaarschnitte mit Waschen und Stylen, langes Haar</p>
            <p class="ml-2">29,00€</p>
          </div>
        </details>
        {/************************************************************************************************** Strähnen*/}
        <details>
          <summary class="border-b-2 border-main pb-3 mt-16">Strähnen</summary>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung Foliensträhnen/Painting kurz <br />(WSF. Pflege, Clossing, Styling, Heimpflegeberatung ganzer Kopf) </p>
            <p class="ml-2">143,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung Foliensträhnen/Painting mittel <br /> (WSF. Pflege, Clossing, Styling, Heimpflegeperatung ganzer Kopf) </p>
            <p class="ml-2">170,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Topleistung Foliensträhnen/Painting lang <br /> (WSF. Pflege, Clossing, Styling, Heimpflegeberatung ganzer Kopf) </p>
            <p class="ml-2">181,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Folien Oberkopf (Scheitel)</p>
            <p class="ml-2">68,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Folien halber Kopf (Scheitel + Konturen)</p>
            <p class="ml-2">83,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Folien ganzer Kopf</p>
            <p class="ml-2">110,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">einzelne Folie</p>
            <p class="ml-2">4,75€ pro Folie</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Highlights 10 Folien</p>
            <p class="ml-2">35,00€</p>
          </div>
        </details>
        {/************************************************************************************************** Augen*/}
        <details>
          <summary class="border-b-2 border-main pb-3 mt-16">Augen</summary>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Wimpern färben</p>
            <p class="ml-2">15,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Augenbrauen färben</p>
            <p class="ml-2">12,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Augenbrauen zupfen</p>
            <p class="ml-2">11,00€</p>
          </div>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Wimpernwelle</p>
            <p class="ml-2">50,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Augenbrauenlifting</p>
            <p class="ml-2">41,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Paket Schöne Augen 1 <br /> (Zupfen, Färben Augenbrauen und Wimpern) </p>
            <p class="ml-2">24,00€</p>
          </div>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Paket Schöne Augen 2 <br /> ( Wimpernwelle, Augebrauenlifting) </p>
            <p class="ml-2">47,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Paket Schöne Augen 3 <br /> (Zupfen, Lifting, Wimpernwelle, Färben Augenbrauen und Wimpern) </p>
            <p class="ml-2">68,00€</p>
          </div>
        </details>
        {/************************************************************************************************** Sonstiges*/}
        <details>
          <summary class="border-b-2 border-main pb-3 mt-16">Sonstiges</summary>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Clossing/Pastel kurz/mittel/lang</p>
            <p class="ml-2">38,00€</p>
          </div>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Tonspülung</p>
            <p class="ml-2">5,50€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Colorfresh Shampoonierung</p>
            <p class="ml-2">9,50€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Pony schneiden</p>
            <p class="ml-2">7,00€</p>
          </div>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Teilcoloration</p>
            <p class="ml-2">32,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Wellaplex kurz</p>
            <p class="ml-2">27,50€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Wellaplex mittel</p>
            <p class="ml-2">35,50€</p>
          </div>
          <div class="flex justify-between  pt-5 pb-1 border-b border-main">
            <p class="font-headline">Wellaplex lang</p>
            <p class="ml-2">35,50€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Pflege Conditioner</p>
            <p class="ml-2">9,00€</p>
          </div>
            <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Pflegemaske Infusion</p>
            <p class="ml-2">14,00€</p>
          </div>
        <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Lipid Booster</p>
            <p class="ml-2">14,00€</p>
          </div>
          <div class="flex justify-between pt-5 pb-1 border-b border-main">
            <p class="font-headline">Haarverlängerungen</p>
            <p class="ml-2">Preis nach Absprache</p>
          </div>
        </details>
        {/************************************************************************************************** Hochzeit*/}
        <details>
          <summary className="border-b-2 border-main pb-3 mt-16">Hochzeit</summary>
          <div className="flex justify-between  pt-5 pb-1 border-b border-main">
            <p className="font-headline">
              Make-up, hochstecken, Probefrisur <br /> Kooperation mit Roberta Mollame <br /> {" "}
              <a href={PriceList} title="Preisliste ansehen" target="_blank" rel="noopener">Zur Preisliste</a>
            </p>
            <a
              href="mailto:brassel.kim@gmx.de?subject=Hochzeitsanfrage%20über%20Website"
              title="Anfragen"
              className="ml-2"
            >
              Anfragen
            </a>
          </div>
        </details>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>Für einen überdurchschnittlichen Aufwand einer Dienstleistung behalten wir uns vor, einen höheren Preis als angegeben in Rechnung zu stellen.</p>
      </div>
    </section>
  </Layout>
)

export default PricingPage
