import React from "react"

export const BigHeadline = ({ children }) => {
  return (
    <h2 className="text-5xl font-normal leading-snug max-w-screen-sm pb-2">
      {children}
    </h2>
  )
}

export const Headline = ({ children }) => {
  return (
    <h2 className="text-2xl font-normal max-w-screen-sm mb-1 ">{children}</h2>
  )
}

export const Subline = ({ children }) => {
  return (
    <p className="font-headline tracking-wider uppercase text-xs">{children}</p>
  )
}
